<template>
  <div class="add-event-rules">
    <el-form
        ref="form"
        :model="model"
        :rules="rules"
        label-width="200px"
        size="small"
    >
      <el-row>
        <el-col :span="12">
          <el-form-item label="触发事件" prop="eventId" required>
            <TriggerEvent ref="couponTriggerEvent" v-model="model.eventId"/>
          </el-form-item>
        </el-col>
        <el-col v-if="couponObject.includes(currentEventKey)" :span="12">
          <el-form-item label="发券对象" prop="userScanQRcode">
            <el-select
                v-model="model.userScanQRcode"
                style="width: 50%"
            >
              <el-option label="用户和车辆" value="0"></el-option>
              <el-option label="仅车辆" value="1"></el-option>
              <el-option label="仅用户" value="2"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col v-if="emitCondition.includes(currentEventKey)" :span="12">
          <el-form-item label="触发条件" prop="parkingPayTrigger" required>
            <el-select
                v-model="model.parkingPayTrigger"
                placeholder="请选择触发条件"
                style="width: 100%"
            >
              <el-option label="单次支付" value="0"></el-option>
              <el-option label="累计支付" value="1"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="12">
          <el-form-item label="事件总触发次数" prop="triggerSum">
            <el-input
                v-model="model.triggerSum"
                placeholder="请输入发券次数"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="事件单日触发次数" prop="dayTrigger">
            <el-input
                v-model="model.dayTrigger"
                placeholder="请输入发券次数(不填代表不限)"
            ></el-input>
          </el-form-item>
        </el-col>
      </el-row>

      <el-row v-if="objectEmitCountLimit.includes(currentEventKey)">
        <el-col :span="7">
          <el-form-item label="对象触发次数限制" prop="objTrigger" required>
            <el-select
                v-model="model.objTrigger"
                placeholder="请选择时间区间"
                style="width: 100%"
            >
              <el-option label="活动期间累计" value="0"></el-option>
              <el-option label="活动期间单日" value="1"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="7" style="margin-left: 10px">
          <el-input v-model="model.objTriggerStart" size="small" style="width: 100%">
            <template slot="append">次</template>
          </el-input>
        </el-col>
        <el-col :span="1" style="margin-left: 60px; margin-top: 10px">
          <span class="line">——</span>
        </el-col>
        <el-col :span="7">
          <el-input v-model="model.objTriggerEnd" placeholder="请输入次数上限(包含,不填即不设上线)" size="small"
                    style="width: 100%">
            <template slot="append">次</template>
          </el-input>
        </el-col>
      </el-row>

      <el-row v-if="objectEmitAmountLimit.includes(currentEventKey)">
        <el-col :span="2">
          <el-form-item label="对象触发金额限制" prop="objTriggerPriceStart" required>
          </el-form-item>
        </el-col>
        <el-col :span="7" style="margin-left: 10px">
          <el-input v-model="model.objTriggerPriceStart" placeholder="请输入金额下限" size="small" style="width: 100%">
            <template slot="append">元</template>
          </el-input>
        </el-col>
        <el-col :span="1" style="margin-left: 60px; margin-top: 10px">
          <span class="line">——</span>
        </el-col>
        <el-col :span="7">
          <el-input v-model="model.objTriggerPriceEnd" placeholder="请输入金额上限(不包含,不填即不设上限)"
                    size="small" style="width: 100%">
            <template slot="append">元</template>
          </el-input>
        </el-col>
      </el-row>

      <div class="btn">
        <el-button size="small" type="primary" @click="back">上一步</el-button>
        <el-button size="small" type="primary" @click="next('form')">下一步</el-button>
      </div>
    </el-form>
  </div>
</template>

<script>
import TriggerEvent from "@/components/TriggerEvent";

export default {
  name: "add-event-rules",
  components: {
    TriggerEvent,
  },
  data() {
    return {
      currentEventKey: '', //当前事件key
      model: {
        eventId: "",
        triggerSum: "",
        dayTrigger: "",
        parkingPayTrigger: "",
        userScanQRcode: "2",
        objTrigger: "",
        objTriggerStart: "",
        objTriggerEnd: "",
        objTriggerPriceStart: "0",
        objTriggerPriceEnd: "",
      },
      rules: {
        eventId: [
          {required: true, message: "请选择触发事件"},
        ],
        objTrigger: [
          {required: true, message: "请选择时间区间"},
        ],
        objTriggerPriceStart: [
          {required: true, message: "请输入金额下限"},
        ],
        parkingPayTrigger: [
          {required: true, message: "请选择触发条件"},
        ],
      },
      //对象触发次数限制
      objectEmitCountLimit: [
        'discount_appointment',
        'discount_user_scan_code',
        'discount_online_payment',
        'discount_trade_aliagent',
        'discout_user_login',
        'discount_trade_recharge',
        'discount_pos_invite_register',
        'discount_recharge_first',
        'discount_user_click',
        'discount_trade_deal',
      ],
      //对象触发金额限制
      objectEmitAmountLimit: [
        'discount_appointment',
        'discount_online_payment',
        'discount_trade_recharge',
        'discount_trade_deal',
      ],
      //发券对象
      couponObject: [
        'discount_user_scan_code',
      ],
      //触发条件
      emitCondition: [
        'discount_online_payment',
      ],
    };
  },
  watch: {
    'model.eventId'(newVal) {
      if (newVal) {
        this.currentEventKey = this.$refs.couponTriggerEvent.options.filter(item => item.id === newVal).map(item => item.key)[0];
      }
    }
  },
  methods: {
    //上一步
    back() {
      this.$emit('back', 'basic');
    },
    //下一步
    next() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.$emit('next', 'rule');
          this.$emit('eventRules', this.model)
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    }
  }
};
</script>

<style lang="less" scoped>
.btn {
  text-align: center;
  margin-top: 40px;
}

.line {
  margin: 40px 0;
}
</style>
