<template>
  <div class="add-coupon-rules">
    <easy-card title="优惠券">
      <div class="add-btn">
        <el-button size="small" type="primary" @click="addCouponRules"
        >添加
        </el-button
        >
      </div>
      <el-table
          :data="couponRulesTable"
          border
          class="common-table"
          size="medium"
          style="width: 100%"
      >
        <el-table-column label="优惠券类型">
          <template slot-scope="scope">
            <span>{{ scope.row.ruleType === "0" ? "面额券" : scope.row.ruleType === "1" ? "时长券" : "免费停车券" }}</span>
          </template>
        </el-table-column>
        <el-table-column label="优惠额度">
          <template slot-scope="scope">
            <span v-if="scope.row.ruleType === '1'">{{ scope.row.faceAmount }}分钟</span>
            <span v-if="scope.row.ruleType === '0'">{{ scope.row.faceAmount }}元</span>
            <span v-if="scope.row.ruleType === '2'">{{  }}</span>
          </template>
        </el-table-column>
        <!-- <el-table-column prop="eventName" label="最高优惠额度">
        </el-table-column> -->
        <el-table-column label="单次获券张数" prop="getNumber">
        </el-table-column>
        <!-- <el-table-column prop="eventName" label="使用次数限制">
        </el-table-column> -->
        <el-table-column label="抵扣方式" prop="eventName">
          <template slot-scope="scope">
            <span v-if="scope.row.ruleType === '1'">{{ scope.row.inAndOut === "0" ? "自驶入" : "自驶出" }}</span>
          </template>
        </el-table-column>
        <el-table-column label="有效期" prop="eventName">
          <template slot-scope="scope">
            <span v-if="scope.row.dateRestrict === '2'">领取到当天24点</span>
            <span v-if="scope.row.dateRestrict === '1'">{{ scope.row.beginDate }}至{{ scope.row.endDate }}</span>
            <span v-if="scope.row.dateRestrict === '0'">领券后{{ scope.row.dayNumber }}天</span>
          </template>
        </el-table-column>
        <el-table-column label="金额限制" prop="eventName">
          <template slot-scope="scope">大于{{ scope.row.restrictAmount }}元</template>
        </el-table-column>
        <el-table-column align="center" label="操作" prop="name" width="200">
          <template slot-scope="scope">
            <el-button size="small" style="font-size: 14px" type="text" @click="deleteRule(scope.row)"
            >删除
            </el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </easy-card>
    <easy-card title="适用范围">
      <el-form
          ref="model"
          :model="model"
          :rules="rule"
          label-width="200px"
          size="small"
      >
        <el-row>
          <el-col :span="6">
            <el-form-item label="适用车场" prop="parkingLotIds" required>
              <!-- <Settlement v-model="model.paySettleAccountId" /> -->
              <SuggestParking v-model="model.parkingLotIds" :multiple="isMultiple"/>
            </el-form-item>
          </el-col>
          <!-- <el-col :span="6" style="margin-left: 10px">
            <SuggestParking v-model="model.parkingLotIds" :multiple="isMultiple" />
          </el-col> -->
          <!-- <el-col :span="1" style="margin-left: -300px">
            <el-button type="primary" size="small" @click="search"
              >搜索</el-button
            >
          </el-col> -->
        </el-row>
        <!-- <el-row>
            <el-col style="margin-left:200px">
          <el-transfer
            filterable
            :filter-method="filterMethod"
            :titles="['所有车场', '所有车场']"
            filter-placeholder="请输入搜索内容"
            v-model="value"
            :data="data"
          >
          </el-transfer>
          </el-col>
        </el-row> -->
      </el-form>
    </easy-card>
    <div class="btn">
      <el-button size="small" type="primary" @click="back">上一步</el-button>
      <el-button size="small" type="primary" @click="submit">提交</el-button>
    </div>

    <!-- 添加优惠券规则弹窗 -->
    <el-dialog
        :append-to-body="true"
        :visible.sync="couponRulesDialogFormVisible"
        class="common-dialog"
        title="添加优惠券规则"
    >
      <el-form
          ref="couponRulesList"
          :model="couponRulesList"
          :rules="rules"
          label-width="200px"
          size="small"
      >
        <el-row>
          <el-col :span="12">
            <el-form-item label="优惠券类型" prop="ruleType" required>
              <el-select
                  v-model="couponRulesList.ruleType"
                  placeholder="请选择优惠券类型"
                  @change="changeRuleType"
              >
                <el-option label="面额券" value="0"></el-option>
                <el-option label="时长券" value="1"></el-option>
                <el-option label="免费停车券" value="2"></el-option>
                <!-- <el-option label="折扣券" value="3"></el-option> -->
              </el-select>
            </el-form-item>
          </el-col>
          <el-col v-if="couponRulesList.ruleType === '2' || couponRulesList.ruleType === ''" :span="12">
            <el-form-item label="优惠额度">
              <el-input
                  v-model="couponRulesList.faceAmount"
                  disabled
              ></el-input>
            </el-form-item>
          </el-col>

          <el-col v-if="couponRulesList.ruleType === '0'" :span="12">
            <el-form-item label="优惠额度" prop="faceAmount" required>
              <el-input
                  v-model="couponRulesList.faceAmount"
                  placeholder="请输入金额/元"
              ></el-input>
            </el-form-item>
          </el-col>

          <el-col v-if="couponRulesList.ruleType === '1'" :span="12">
            <el-form-item label="优惠额度" prop="faceAmount" required>
              <el-input
                  v-model="couponRulesList.faceAmount"
                  placeholder="请输入时长/分钟"
              ></el-input>
            </el-form-item>
          </el-col>

        </el-row>

        <el-row>
          <el-col :span="12">
            <el-form-item label="单次获券张数" prop="getNumber" required>
              <el-input-number
                  v-model="couponRulesList.getNumber"
                  :min="1"
                  size="small"
              ></el-input-number>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="使用金额限制" prop="restrictAmount" required>
              <el-input
                  v-model="couponRulesList.restrictAmount"
                  placeholder="大于"
              >
                <template slot="append">元</template>
              </el-input>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row>
          <el-col v-if="couponRulesList.ruleType === '1'" :span="12">
            <el-form-item label="抵扣方式" prop="inAndOut">
              <el-select
                  v-model="couponRulesList.inAndOut"
              >
                <el-option label="自驶入" value="0"></el-option>
                <el-option label="自驶出" value="1"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>

        <!-- <el-row>
          <el-col :span="12" v-if="couponRulesList.ruleType === '2'">
            <el-form-item label="使用次数限制" prop="couponAmount" required>
               <el-input
                v-model="couponRulesList.amountLimit"
                placeholder="请输入使用次数限制(0代表不限)"
              >
              </el-input>
            </el-form-item>
          </el-col>
        </el-row> -->

        <!-- <el-row>
          <el-col :span="12" v-if="couponRulesList.ruleType === '3'">
            <el-form-item label="优惠金额限制" prop="couponAmount" required>
               <el-input
                v-model="couponRulesList.amountLimit"
                placeholder="优惠金额限制(0代表不限)"
              >
              </el-input>
            </el-form-item>
          </el-col>
        </el-row> -->

        <el-row>
          <el-col :span="24">
            <el-form-item label="有效期限制" prop="dateRestrict" required>
              <el-radio-group v-model="couponRulesList.dateRestrict" @change="radioChange">

                <el-radio label="0">
                  <span style="display: inline-block; margin-right: 20px; margin-top:12px">固定天数</span>
                  <div style="display: inline-block;">
                    <el-input v-model="couponRulesList.dayNumber" :disabled="dayDisabled"
                              style="margin-top: -10px; margin-left:28px">
                      <template slot="append">天</template>
                    </el-input>
                  </div>
                </el-radio>

                <div>
                  <el-radio label="1">
                    <span style="display: inline-block; margin-right: 20px; margin-top:20px;">固定日期</span>
                  </el-radio>
                  <div style="display: inline-block;">
                    <el-date-picker
                        v-model="couponRulesList.beginDate"
                        :disabled="dateDisabled"
                        placeholder="开始日期"
                        type="date"
                        value-format="yyyy-MM-dd">
                    </el-date-picker>
                    <el-date-picker
                        v-model="couponRulesList.endDate"
                        :disabled="dateDisabled"
                        placeholder="结束日期"
                        type="date"
                        value-format="yyyy-MM-dd">
                    </el-date-picker>
                  </div>
                </div>
                <div>
                  <el-radio label="2">
                    <span style="display: inline-block; margin-right: 20px; margin-top:16px;">领取当天24点</span>
                  </el-radio>
                </div>
              </el-radio-group>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="cancel">取 消</el-button>
        <el-button type="primary" @click="saveList"
        >确 定
        </el-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<script>
// import Settlement from "@/components/Settlement";
import SuggestParking from "@/components/SuggestParking";

export default {
  name: "add-coupon-rules",
  components: {
    // Settlement,
    SuggestParking,
  },
  data() {
    return {
      isMultiple: true, //停车场多选
      couponRulesDialogFormVisible: false, //添加优惠券规则弹窗显示
      dayDisabled: true,
      dateDisabled: true,
      couponRulesTable: [],
      couponRulesList: {
        ruleType: "",
        faceAmount: "",
        couponAmount: "",
        restrictAmount: "",
        dateRestrict: "2",
        dayNumber: "",
        beginDate: "",
        endDate: "",
        getNumber: "",
        inAndOut: "",//自驶入，自驶出
      },
      rules: {
        ruleType: [
          {required: true, message: "请选择优惠券类型"},
        ],
        // faceAmount: [
        //   { required: true, message: "优惠额度不能为空" },
        // ],
        dateRestrict: [
          {required: true, message: "请选择有效期限制类型"},
        ],
        restrictAmount: [
          {required: true, message: "使用金额限制不能为空"},
        ]
      },
      model: {
        paySettleAccountId: "",
        parkingLotIds: [],
      },
      rule: {
        parkingLotIds: [
          {required: true, message: "请选择车场"},
        ]
      }
    };
  },
  methods: {
    //优惠券类型改变
    changeRuleType() {
      this.couponRulesList.faceAmount = "";
    },
    //上一步
    back() {
      this.$emit("back", "event");
    },
    //提交
    submit() {
      this.$emit('couponRules', this.couponRulesTable, this.model)
    },
    //有效期限制改变
    radioChange() {
      if (this.couponRulesList.dateRestrict === "0") {
        this.dayDisabled = false;
        this.dateDisabled = true;
      } else if (this.couponRulesList.dateRestrict === "1") {
        this.dateDisabled = false;
        this.dayDisabled = true;
      } else if (this.couponRulesList.dateRestrict === "2") {
        this.dayDisabled = true;
        this.dateDisabled = true;
      }
    },
    //添加按钮
    addCouponRules() {
      this.couponRulesDialogFormVisible = true;
    },
    //删除已添加的
    deleteRule(e) {
      const couponList = this.couponRulesTable;
      const id = e;
      couponList.splice(couponList.indexOf(id), 1);
    },
    //弹窗取消
    cancel() {
      this.couponRulesDialogFormVisible = false;
    },
    //弹窗确定按钮
    saveList() {
      if (this.couponRulesList.ruleType === "0" || this.couponRulesList.ruleType === "1") {
        if (this.couponRulesList.faceAmount === "") {
          this.$message({
            message: '优惠额度不能为空',
            type: 'warning'
          });
          return false;
        }
      }
      if (this.couponRulesList.dateRestrict === "0") {
        if (this.couponRulesList.dayNumber === "") {
          this.$message({
            message: '固定天数不能为空',
            type: 'warning'
          });
          return false;
        }
      }
      if (this.couponRulesList.dateRestrict === "1") {
        if (this.couponRulesList.beginDate === "") {
          this.$message({
            message: '请选择开始日期',
            type: 'warning'
          });
          return false;
        }
        if (this.couponRulesList.endDate === "") {
          this.$message({
            message: '请选择结束日期',
            type: 'warning'
          });
          return false;
        }
      }
      this.$refs.couponRulesList.validate((valid) => {
        if (valid) {
          const dataList = JSON.parse(JSON.stringify(this.couponRulesList));
          console.log(dataList);
          this.couponRulesTable.push(dataList);
          this.couponRulesDialogFormVisible = false;

          this.$refs.couponRulesList.resetFields();
          this.couponRulesList.faceAmount = "";
          this.couponRulesList.couponAmount = "";
          this.couponRulesList.dayNumber = "";
          this.couponRulesList.beginDate = "";
          this.couponRulesList.endDate = "";
          this.couponRulesList.inAndOut = "";
          this.dayDisabled = true;
          this.dateDisabled = true;
          console.log(this.couponRulesTable);
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    }
  },
};
</script>

<style lang="less" scoped>
.add-btn {
  text-align: right;
  margin-bottom: 16px;
}

.btn {
  text-align: center;
  margin-top: 40px;
}
</style>
