<template>
  <div class="add-coupon-basic">
    <el-form
        ref="form"
        :model="model"
        :rules="rules"
        label-width="200px"
        size="small"
    >
      <el-row>
        <el-col :span="12">
          <el-form-item label="标题" prop="title" required>
            <el-input
                v-model="model.title"
                placeholder="请输入标题内容"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="适用业务类型" prop="couponType" required>
            <el-select
                v-model="model.couponType"
                placeholder="适用业务类型"
                style="width: 100%"
            >
              <el-option label="通用券" value="0"></el-option>
              <el-option label="停车券" value="1"></el-option>
              <el-option label="洗车券" value="2"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>

      <el-row>
        <el-form-item label="参与结算" prop="isSettle" required>
          <el-radio-group v-model="model.isSettle">
            <el-radio label="0">参与</el-radio>
            <el-radio label="1">不参与</el-radio>
          </el-radio-group>
        </el-form-item>
      </el-row>
      <el-row>
        <el-col :span="12">
          <el-form-item label="结算方名称" prop="paySettleAccountId" required>
            <Settlement v-model="model.paySettleAccountId"/>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="承担方" prop="bearer" required>
            <!-- <Bearer v-model="model.bearer" style="width: 100%" /> -->
            <el-select
                v-model="model.bearer"
                placeholder="请选择-承担方"
                style="width: 100%"
            >
              <el-option label="平台" value="0"></el-option>
              <el-option label="结算方" value="1"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>

      <el-row>
        <el-col :span="12">
          <el-form-item label="上架时间" prop="putawayDate" required>
            <el-date-picker
                v-model="model.putawayDate"
                :clearable="false"
                :picker-options="startPickerOptions"
                placeholder="请选择上架时间"
                style="width: 100%"
                type="date"
                value-format="yyyy-MM-dd"
            >
            </el-date-picker>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="下架时间" prop="soldoutDate" required>
            <el-date-picker
                v-model="model.soldoutDate"
                :disabled="isSelectStartDate"
                :picker-options="endPickerOptions"
                placeholder="请选择下架时间"
                style="width: 100%"
                type="date"
                value-format="yyyy-MM-dd"
            >
            </el-date-picker>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="12">
          <el-form-item label="备注" prop="remark">
            <el-input
                v-model="model.remark"
                maxlength="300"
                placeholder="请输入备注内容(字数限制300字以内)"
                show-word-limit
                type="textarea"
            >
            </el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <div class="btn">
        <el-button size="small" type="primary" @click="cancel">取消</el-button>
        <el-button size="small" type="primary" @click="next('form')">下一步</el-button>
      </div>
    </el-form>
  </div>
</template>

<script>
import Settlement from "@/components/Settlement";
// import Bearer from "@/components/Bearer";
export default {
  name: "add-coupon-basic",
  components: {
    Settlement,
    // Bearer
  },
  watch: {
    "model.putawayDate"(newVal) {
      if (newVal !== "") {
        this.isSelectStartDate = false;
        const time = newVal + " 00:00:00";
        const dataTmp = time.replace(/-/g, '/');
        this.startDate = new Date(dataTmp).getTime();
      } else {
        this.isSelectStartDate = true;
      }
    }
  },
  data() {
    return {
      id: '1',
      isSelectStartDate: true,
      startDate: "",//上架时间时间戳
      model: {
        title: "",
        couponType: "1",
        paySettleAccountId: "",
        bearer: "",
        putawayDate: "",
        soldoutDate: "",
        remark: "",
        isSettle: "0",
      },
      rules: {
        title: [
          {required: true, message: "标题不能为空"},
        ],
        couponType: [
          {required: true, message: "请选择适用业务类型"},
        ],
        isSettle: [
          {required: true, message: "请选择是否参与结算"}
        ],
        paySettleAccountId: [
          {required: true, message: "请选择结算方"},
        ],
        bearer: [
          {required: true, message: "请选择承担方"},
        ],
        putawayDate: [
          {required: true, message: "请选择上架时间"},
        ],
        soldoutDate: [
          {required: true, message: "请选择下架时间"},
        ]
      },
      startPickerOptions: {
        disabledDate(time) {
          return time.getTime() < Date.now() - 3600 * 1000 * 24;
        },
      },
      endPickerOptions: {},
    };
  },
  methods: {
    //取消
    cancel() {
      this.$router.back();
    },
    //监听是否参与结算
    // changeIsSettle(e) {
    //   if(e === "1") {
    //     this.model.paySettleAccountId = "";
    //     this.model.bearer = "";
    //   }
    // },
    //下一步
    next() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.$emit('next', 'event');
          this.$emit('basicInfo', this.model)
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    }
  },
  created() {
    var that = this;
    this.$nextTick(() => {
      this.endPickerOptions = {
        disabledDate(time) {
          return time.getTime() < that.startDate + 3600 * 1000 * 24;
        },
      }
    });
  }
};
</script>

<style lang="less" scoped>
.btn {
  text-align: center;
  margin-top: 40px;
}
</style>
