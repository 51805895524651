<template>
  <div v-loading="isLoading" class="page">
    <div class="tabs-wrap">
      <el-tabs v-model="currentTab" type="border-card">
        <el-tab-pane disabled label="基础信息" name="basic">
          <BasicInfo @basicInfo="saveBasicInfo($event)" @next="onNext"/>
        </el-tab-pane>
        <el-tab-pane disabled label="事件规则" name="event">
          <EventRules @back="onBack" @eventRules="saveEventRules($event)" @next="onNext"/>
        </el-tab-pane>
        <el-tab-pane disabled label="优惠券规则" name="rule">
          <CouponRules @back="onBack" @couponRules="saveCouponRules"/>
        </el-tab-pane>
      </el-tabs>
      <el-button class="back-button" type="text" @click="goBack"
      ><i class="el-icon-back"></i>上一页
      </el-button
      >
    </div>
  </div>
</template>

<script>
import BasicInfo from "./components/basic_info.vue";
import EventRules from "./components/event_rules.vue";
import CouponRules from "./components/coupon_rules.vue";
import {addCouponPublishRule} from "@/api/coupon";

export default {
  name: "add_price",
  components: {
    BasicInfo,
    EventRules,
    CouponRules,
  },
  data() {
    return {
      isLoading: false,
      id: "",
      currentTab: 'basic',
      basicInfo: {},
      eventRules: {},
      couponRules: [],
      couponModel: {},
      currentStatus: '0'
    }
  },
  methods: {
    //返回上一页
    goBack() {
      this.$router.back();
    },
    onBack(e) {
      this.currentTab = e;
    },
    onNext(e) {
      this.currentTab = e;
    },
    //保存来自基础信息页面的参数
    saveBasicInfo(value) {
      this.basicInfo = value;
    },
    //保存事件规则页面参数
    saveEventRules(value) {
      console.log(value);
      this.eventRules = value;
    },
    //保存优惠券规则页面参数
    saveCouponRules(params1, params2) {
      this.couponRules = params1;
      this.couponModel = params2;
      const data = {
        title: this.basicInfo.title,
        couponType: this.basicInfo.couponType,
        isSettle: this.basicInfo.isSettle,
        paySettleAccountId: this.basicInfo.paySettleAccountId,
        bearer: this.basicInfo.bearer,
        putawayDate: this.basicInfo.putawayDate,
        soldoutDate: this.basicInfo.soldoutDate,
        remark: this.basicInfo.remark,
        eventId: this.eventRules.eventId,
        triggerSum: this.eventRules.triggerSum,
        dayTrigger: this.eventRules.dayTrigger,
        parkingPayTrigger: this.eventRules.parkingPayTrigger,
        userScanQRcode: this.eventRules.userScanQRcode,
        objTrigger: this.eventRules.objTrigger,
        objTriggerStart: this.eventRules.objTriggerStart,
        objTriggerEnd: this.eventRules.objTriggerEnd,
        objTriggerPriceStart: this.eventRules.objTriggerPriceStart,
        objTriggerPriceEnd: this.eventRules.objTriggerPriceEnd,
        publishRuleList: this.couponRules,
        parkingLotIds: this.couponModel.parkingLotIds,
      }
      if (this.couponRules.length === 0) {
        this.$message({
          message: '请添加优惠券规则',
          type: 'warning'
        });
        return false;
      }
      if (this.couponModel.parkingLotIds.length === 0) {
        this.$message({
          message: '请选择适用车场',
          type: 'warning'
        });
        return false;
      }
      this.isLoading = true;
      addCouponPublishRule(data).then(res => {
        this.isLoading = false;
        if (res.code === 30 && res.result) {
          console.log(res);
          this.$message({
            message: '保存成功',
            type: 'success'
          });
          this.goBack();
        }
      })
    }
  },
};
</script>

<style lang="less" scoped>
.tabs-wrap {
  position: relative;

  .back-button {
    position: absolute;
    right: 20px;
    top: 0;
    font-size: 15px;
    font-weight: normal;
    display: flex;
    flex-direction: row;
    align-items: center;
    line-height: 18px;

    i {
      font-size: 18px;
    }
  }
}
</style>
